<template lang="pug">
  v-card
    v-card-title
        | Inactive Detected
        v-spacer
            v-icon(aria-label="Close" @click="$emit('close')")
                | mdi-close
    v-card-text
      | Inactivity detected more than 10 minutes.
      br
      | Your session will expire in {{time}} seconds
</template>

<script>
  /* eslint-disable */
    import { auth } from '@/plugins/auth/firebase'
    import Cookie from 'js-cookie'
    export default {

        data () {
            return {
                time: 10,
            }
        },

        computed: {
            isIdle () {
                return this.$store.state.idleVue.isIdle
            },
        },

        async created () {
            const vm = this
            vm.$store.dispatch('idle/setCurrentItem', false)
            let timerId = setInterval(async () => {
                vm.time -= 1
                if (!vm.isIdle) {
                    clearInterval(timerId)
                }
                if (vm.time < 1) {
                  vm.$store.dispatch('idle/setCurrentItem', true)
                  clearInterval(timerId)
                  await auth.signOut()
                  // await Cookie.remove('access_token')
                  vm.$store.dispatch('users/clearUser').then(function () {
                    vm.$router.push('/pages/lock').catch(err => {
                      var event = new CustomEvent("idle:stop", {});
                      window.dispatchEvent(event);
                      // console.log(err)
                    })
                  })
                }
            }, 1000)
        },

    }
</script>

<style lang = 'scss'  scoped>

    $break-large: 1200px;
    $primary-color: #c29f52;
    .question{
        font-size: 1.3rem;
        color: rgb(132,132,132);
    }
    .select .v-input__control{
        width: 100% !important;
    }
    .menuable__content__active{
        top: 60px !important;
        left: 0 !important;
    }
    label.v-label{
        color: #000 !important;
    }
    .v-input__slot:before{
        background: $primary-color !important;
    }
    .user-login__field{
        width: 250px;
    }
    .user-login__field input[type="text"]{
        text-align: center !important;
    }

    .profile{
        .profile__title{
            font-size: 2rem;
            font-weight: 900;

            color: rgba(#000,0.7);
        }
        .profile__avatar{

            border-radius: 50%;

        }
        .avatar-container{
            border-radius: 50%;
            border: 1px solid rgba(red,0.4);
            overflow: hidden;
            padding: 5px;
        }
    }

</style>
